@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,600;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


body {
  margin: 0;
  font-family:  'Poppins',-apple-system, BlinkMacSystemFont,'Poppins', 'Courier New', Courier, monospace, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  background-color: white;

 
  
}
/*nav bar*/
.bg-light{
  background-color: #f0f0f0 !important;
}

.btn {
  font-weight: 500;
  transition: .5s;
  border-radius: 0px !important;
}

.btn.btn-primary {
  color: #FFFFFF;
}

.btn-sm-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 32px;
  font-weight: normal;
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

.navbar-light .navbar-nav .nav-link {
  margin-right: 30px;
  padding: 25px 0;
  color: #FFFFFF;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  outline: none;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: blue;
}

@media (max-width: 991.98px) {
  .navbar-light .navbar-nav .nav-link  {
      margin-right: 0;
      padding: 10px 0;
  }

  .navbar-light .navbar-nav {
      border-top: 1px solid #EEEEEE;
  }
}

.navbar-light .navbar-brand,
.navbar-light a.btn {
  height: 75px;
}

.navbar-light .navbar-nav .nav-link {
  color: black;
  font-weight: 500;
}

.navbar-light.sticky-top {
  top: -100px;
  transition: .5s;
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
      display: block;
      border: none;
      margin-top: 0;
      top: 150%;
      opacity: 0;
      visibility: hidden;
      transition: .5s;
  }

  .navbar .nav-item:hover .dropdown-menu {
      top: 100%;
      visibility: visible;
      transition: .5s;
      opacity: 1;
  }
}
/*navbar*/
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-link{
  color: black !important;
}
.navbar-brand{
  color: black !important;
}
.navbar-toggler{
border: none !important;

}
.btn5{
  background-color: #1b6cfc !important;
  color: white;
  border: none;
  font-family: 'Poppins';
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 18px;
  width: 100% !important;
}
.navbar-toggler-icon{
  font-size: 12px;
}
h1{
  font-size: 15px !important;
}
.flex-container{
  display: flex !important;
}

.fa-plus{
    color:black;
    margin:5px;
    background-color: #ffffff;
    padding: 5px;
    border-radius: 3px;
    border:1px solid #ddd;
}
.sticky{
position:sticky;
}
.bold-text {
  font-weight: bolder !important;
}
.fot{
  border-top:1px solid rgb(235, 235, 235) !important;
   background-color: #ffffff !important ;
}
.fa-minus{
color: black;

font-weight: bolder;
background-color: #ffffff;
padding: 5px;
border-radius: 3px;
border:1px solid #ddd;
}
.total-price{
  padding-top: 5px !important;
  justify-content: space-between;
}
.fa-trash{
  color:grey;
  margin-right: 27px !important;
}
input{
  margin-top:10px !important;
}
.search-bar{
  width: 650px;
  margin: 0 auto !important;
 
}

.category-buttons {
  display: flex;
  justify-content: center;
  gap: 4px;
}

.category-btn {
  width: 95px  !important;
  background-color: #f0f0f0;
  border: 0px solid #ddd;
  color: #333;
  padding: 7px 4px;
  border-radius: 2px;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 11px;
  margin: 0 2px;
  
  height: 40px !important;
}
.ll{
  background-color: #dbf2ee   !important;
}
.category-btn:hover {
  background-color: black;
}

.category-btn.active {
  background-color: black;
  border-color: black;
  color: white;
}

.category-slide {

  justify-content: space-around;
  align-items: center;
}

/* Footer.css */
.footer-fixed {
  position: sticky !important;
  bottom: 0;
  width: 100%;
  z-index: 1000;
background-color: #ffffff !important;
}

.sidebar {
  width: 250px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -250px; /* Masquer le sidebar par défaut */
  z-index: 1002;
  transition: left 0.3s ease;
}

.sidebar.open {
  left: 0; 
  z-index: 1002;/* Afficher le sidebar quand il est ouvert */
}

.side-light{
background: #FBFBFB !important;
}


a.list-group-item.list-group-item-action {
  font-family: poppins;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
  letter-spacing: 1px;
  font-size: 15px;
  margin-top:20px;
  border:none;
}




.list-group a i {
  background: #0d6efd;
   /* #163052*/
  width: 50px;
  border-radius: 2px;
  padding: 5px 10px !important;
  color: #fff;
  margin-right: 10px;
   
}

.list-group .nav-link{
  font-family:poppins;
  font-weight: 600;
}

button.category-btn.text-truncate.col-xs-3.col-3.col-md-3 {
  font-family: 'Poppins';
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 10px;
    background: #1b6cfc;
     /* #163052*/
    color: #fff;
    border-radius: 10px;
}


.main-content {
  width: 100%;
  transition: margin-left 0.3s ease;
}

.main-content.sidebar-open {
  margin-left: 250px;
  z-index: 1000;  /* Déplacer le contenu principal quand le sidebar est ouvert */
}


.check{
  gap:10px;
}
.toolbar-inner {
  padding: 10px 0;
 
  background-color: #ffffff !important/* Change as needed */
}
.toolbar-inner1{
  padding: 10px 0;
  padding-left: 110px;
  background-color: #ffffff !important; /* Change as needed */
}

   .shop-card{position: relative;} 
.shop-card .product-tag {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color:#ffff;
  background-color: #0D775E !important;
  border-radius: 0;
  padding: 4px 0px;
  border: 0;
  font-size: 12px;
  font-weight: 350;
  width: 41px;
  height:17px;
  font-family:sans-serif;
}
.shop-card .product-tag1 {
  position: absolute;
  top: 9px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color:#ffff;

  border-radius: 0;
  padding: 4px 0px;
  border: 0;
  font-size: 12px;
  font-weight: 350;
  width: 41px;
  height:17px;
  font-family:sans-serif;
}

.bi{
  
  font-size: 70px;

}
.testem{
  gap:70px;
}
.spanicon{
  font-size: 40px;
}
.cart-product{
      background-color: #fff;
    box-shadow: 4px 4px 30px -5px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 4px 4px 30px -5px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 4px 4px 30px -5px rgba(0, 0, 0, 0.08);
    border-radius: 30px;
    width: calc(50% - 50px);
    padding: 20px;
    margin-right: 15px;
    margin-bottom: 15px;
    text-align: center;
    border:1px solid #e9ecef;
}

footer span{
  font-family: poppins;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

footer i{

  background: #0d6efd;
   /* #163052*/
    width: 50px;
    border-radius: 2px;
 
    color:#fff;
}


.product-price{
  padding-left:10px !important;
  padding-right:10px !important;
  width:100%
}

.product-price{
  padding-left:10px !important;
  padding-right:10px !important;
  width:100%
}

.product-price h3{
  font-size: 15px;
    text-align: center;
    color: #1b6cfc;
    font-weight: bold !important;
    margin-top:30px;
    font-family: "Poppins", sans-serif;
}

.product-quantity{
  width:100%;

}

.product-quantity .form-control{
  font-size: 10px;
  width: 70px;
  height: 25px;
  border: none;
  background-color: #eef3ff;
  padding: 15px 10px;
  border-radius: 25px;
  text-align: center;
  font-family: poppins;
  font-weight: bold;
}


.product-quantity .grat-title{

  font-family:poppins;
  font-size: 11px;
  font-weight: 600;
  text-transform:  uppercase;

}


.product-quantity .grat-title input{

  font-size: 7px;
    margin-top: 20px !important;
    margin-right: 5px !important;
    width: 15px;
    background-color: #eef3ff !important;
    border-radius: 1px !important;

}

.mt-3.col-12.col-md-12.cart-product h6 {
  font-family: 'Poppins';
  font-weight: 600;
  letter-spacing: 1px;
}

.search-bar  .form-control{
  width: calc(100% - 10px);
  border: none;
  background-color: #eef3ff;
  padding: 15px 10px;
  border-radius: 25px;
  font-family: poppins;
  font-weight: 600;
  font-size: 14px;
  color: #000 ! IMPORTANT;
  text-transform: uppercase;
}

.App{
  background:#fdfdfd;
}


.cart-details H2{
  font-family: poppins;
  font-weight: 700;
  letter-spacing: 2px;
  font-size: 24px;
  text-transform: uppercase;
  margin-top: 20px !important;
  color: #1b6cfc;
}
.cart-details{
  background-color: #fbfbfb;

}
.cart-details .bg-white {
  background-color: #fff;
    box-shadow: 4px 4px 30px -5px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 4px 4px 30px -5px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 4px 4px 30px -5px rgba(0, 0, 0, 0.08);
    border-radius: 30px;
    padding: 20px;
    margin-right: 15px;
    margin-bottom: 15px;
    text-align: center;
    border: 1px solid #e9ecef;
}

.cart-details h3 {
  font-family: 'Poppins';
  font-weight: 600 !important;
  letter-spacing: 1px;
}

.cart-details .cart-price {
  margin-top: 5px;
    padding-left: 10px;
}
.cart-details .cart-price h6 {
  display: inline;
    font-size: 15px;
    text-align: center;
    color: #1b6cfc;
    font-weight: bold !important;
    font-family: "Poppins", sans-serif;
}
.cart-details .cart-quant {
  margin-top:10px;
}

.cart-details .cart-quant .fa-minus, .cart-details .cart-quant .fa-plus {
  color: white;
  font-weight: bolder;
  background-color: #1b6cfc;
  padding: 5px;
  border-radius: 0px;
  border: none;
}




.cart-details .cart-quant b {
 font-family: poppins;
 font-size: 15px !important;
}


.header{
  box-shadow: 4px 4px 30px -5px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 4px 4px 30px -5px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 4px 4px 30px -5px rgba(0, 0, 0, 0.08);
}


.cart-client-infos {
  background-color: #fff;
  box-shadow: 4px 4px 30px -5px rgba(0, 0, 0, 0.08) !important;
  -webkit-box-shadow: 4px 4px 30px -5px rgba(0, 0, 0, 0.08) !important;
  -moz-box-shadow: 4px 4px 30px -5px rgba(0, 0, 0, 0.08) !important;
  border-radius: 2px !important;
  padding: 20px !important;
  margin-right: 15px !important;
  margin-bottom: 15px !important;
  border: 1px solid #e9ecef !important;
}

.cart-client-infos h2 {
  font-size: 20px;
  margin-bottom: 25px;
}
.cart-client-infos .form-control {
  width: calc(100% - 10px);
  border: none;
  background-color: #eef3ff;
  padding: 15px 10px;
  border-radius: 25px;
  font-family: poppins;
  font-weight: 600;
  font-size: 12px !important;
  color: #585858 ! IMPORTANT;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
}


.cart-footer  .flex-container{
 padding:20px;
}

.cart-footer  .total-price span{
  font-family: poppins;
    font-weight: 900;
    letter-spacing: 2px;
    font-size: 20px;
    text-transform: uppercase;
    color: #1b6cfc;
    margin-top:0px !important; 
 }


 .cart-footer  .total-price {
    margin-top: 0px !important;

 }
 .Toastify__toast-body > div:last-child {

  font-family: 'Poppins';
  font-weight: 600;
  color: #000;
}


.orders-details .form-control {
  width: calc(100% - 10px);
  border: none;
  background-color: #eef3ff !important;
  padding: 15px 10px;
  border-radius: 25px !important;
  font-family: poppins;
  font-weight: 600;
  font-size: 12px !important;
  color: #585858 ! IMPORTANT;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
}

.orders-details .titre-pages h6{
  font-family: poppins;
  font-weight: 700;
  letter-spacing: 2px;
  font-size: 24px;
  text-transform: uppercase;
  margin-top: 30px !important;
  margin-bottom:20px;
  color: #1b6cfc;
}

.order-items{
  background-color: #fff;
  box-shadow: 4px 4px 30px -5px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 4px 4px 30px -5px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 4px 4px 30px -5px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  padding: 20px;
  margin-right: 15px;
  margin-bottom: 15px;
  margin-top:20px;
  text-align: center;
  border: 1px solid #e9ecef;

}

.order-items .card-body{

  border-bottom: 1px solid #ddd;
}
.order-items .card-body .card-title {

  font-family:poppins;
  font-size:12px !important;
}
.order-items .card-body .card-title {

  font-family:poppins;
  font-size:12px !important;
}
.order-items .card-body .card-price {

  font-size: 14px;
    font-weight: bold;
    font-family: 'Poppins';
    color: #0d6efd;
    LETTER-SPACING: 1px;
}

.details-order-table h6{
font-family: poppins;
font-size: 12px;
text-transform: uppercase;
letter-spacing:1px;
font-weight: 600;
text-align:left;
margin:0px;
}
.details-order-table span{
  font-family: poppins;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing:1px;
  font-weight: 600;
  margin-left:10px;
  padding:0px;
  
  }
  .details-order-table {
    width:100%;
    margin-bottom:30px;
    background-color: #edf2fe;
     }
.details-order-table td {
  text-align:left;
  padding:10px;
  }

  .details-order-table tr {
    padding:50px;
    } 
    
    .details-order-table button {
      background: #0d6efd;
      font-family: poppins;
      text-transform: uppercase;
      font-weight: 600;
      border: none;
      padding: 10px 20px;
      color: #fff;
      letter-spacing: 2px;
      font-size: 12px;
      margin-bottom:10px;
      }

      .button-export{
        text-align:center !important;
      }


.login-page h2{

  font-family: poppins;
  font-weight:bold;
  font-size:40px !important;
  letter-spacing:2px;
  margin-bottom:50px !important;
}
.login-page .form-control {
  width: calc(100% - 10px);
  border: none;
  background-color: #eef3ff;
  padding: 15px 10px;
  border-radius: 25px;
  font-family: poppins;
  font-weight: 600;
  font-size: 14px;
  color: #000 ! IMPORTANT;
  text-transform: uppercase;
  height:60px;
  margin-bottom:30px;
   }

   .login-page .form-control::placeholder {
   font-size: 15px;
   letter-spacing: 2px;
   padding-left:20px;
     }
     .login-page button {
      height: 60px !important;
    margin-top: 10px !important;
    border-radius: 50px;
        }
/**/
/*projects*/
.builder_inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.builder_item {
  position: relative;

}

.builder_item img {
  width: 100%;
  height: auto;
  display: block;
}

.hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
}

.builder_item:hover .hover {
  opacity: 1;
}

.hover h4 {
  margin-bottom: 10px;
  font-size: 20px;
}

.hover p {
  font-size: 14px;
}
/*testemonials*/
.testimonials_area {
  background: #f9f9ff;
  position: relative;
}
.p_120 {
  padding-top: 120px;
  padding-bottom: 120px;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  -moz-backface-visibility: hidden;
}


.testi_item .media img {
  border-radius: 50%; /* Makes the image circular */
  width: 80px; /* Adjust the size as needed */
  height: 80px; /* Adjust the size as needed */
  object-fit: cover; /* Ensures the image covers the circular area */
}